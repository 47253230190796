import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserModel } from '@shared/authentication/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserState {
  public user$ = new BehaviorSubject<UserModel>(null);

  constructor() {}
}
