import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserLogin } from 'app/auth/auth.types';
import { SettingsService } from '@api/settings.service';
import { UserModel } from './interfaces';
import { UserState } from '@shared/states/user.state';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private settingsService: SettingsService,
    private userState: UserState,
  ) {}

  public getCode(emailOrPhone: string): Observable<Object> {
    return this.http
      .post(`${this.settingsService.v2}client/configurator/auth-code`, {
        login: emailOrPhone,
      })
      .pipe(catchError(() => of(null)));
  }

  public confirmCode(userLogin: string, code: string, saveOrder = false): Observable<unknown> {
    return this.http
      .post(`${this.settingsService.v2}client/configurator/auth-confirm`, {
        login: userLogin,
        code,
        saveOrder,
      })
      .pipe(catchError(() => of(null)));
  }

  public anonymousLogin(): Observable<UserModel> {
    return this.http
      .get<UserModel>(`${this.settingsService.v2}client/configurator/auth-anonymous`)
      .pipe(catchError(() => of(null)));
  }

  public authCheck(): Observable<boolean> {
    return this.http
      .get<{ auth: boolean }>(`${this.settingsService.v2}client/configurator/auth-check`)
      .pipe(
        catchError(() => of(null)),
        map((result) => result.auth),
      );
  }

  public getPhoneCode(phone: string): Observable<number> {
    return this.http
      .post<number>(`${this.settingsService.v1}user/auth-phone/request-phone-code`, {
        phone: phone,
      })
      .pipe(catchError(() => of(null)));
  }

  public existingUserByPhone(phone: string): Observable<{ isUserExists: boolean }> {
    return this.http
      .post<{ isUserExists: boolean }>(`${this.settingsService.v1}user/auth-phone/is-user-exists`, {
        phone: phone,
      })
      .pipe(catchError(() => of(null)));
  }

  public loginByPhone(phone: string, phoneCode: number): Observable<any> {
    return this.http
      .post<any>(`${this.settingsService.v1}user/auth-phone/login-by-code`, {
        phone: phone,
        phoneCode: phoneCode,
      })
      .pipe(catchError(() => of(null)));
  }

  public signupByPhone(phone: string, phoneCode: number): Observable<any> {
    return this.http
      .post<any>(`${this.settingsService.v1}user/auth-phone/signup`, {
        phone: phone,
        phoneCode: phoneCode,
      })
      .pipe(catchError(() => of(null)));
  }

  public loginByUsername(user: UserLogin): Observable<UserModel> {
    return this.http
      .post<UserModel>(`${this.settingsService.v0}site/login`, user)
      .pipe(catchError(() => of(null)));
  }

  public logout(): Observable<any> {
    return this.http.get<any>(`${this.settingsService.v2}client/configurator/auth-logout`);
  }

  public validate(): Observable<boolean> {
    return this.http.get<UserModel>(`${this.settingsService.v0}user/user/info`).pipe(
      catchError((e) => of(null)),
      map((result) => {
        if (result?.user_id) {
          this.userState.user$.next(result);
          localStorage.setItem('user_id', `${result?.user_id}`);

          return true;
        }

        return false;
      }),
    );
  }

  /*phoneCheck(phone) {
    const url = `${ this.url(this.requestUrls.CHECK_PHONE, API_VERSION.V1) }`;
    this.currentPhone.next(phone);
    return this.http.post(url, { phone: phone });
  }

  phoneGetCode(_phone?) {
    const phone = _phone ? _phone : this.currentPhone.value;
    const url = `${ this.url(this.requestUrls.GET_CODE, API_VERSION.V1) }`;
    return this.http.post(url, { phone: phone });
  }

  phoneSignUp(phoneCode, _url?, _phone?) {
    const phone = _phone ? _phone : this.currentPhone.value;
    const url = `${ this.url(this.requestUrls.PHONE_SIGN_UP, API_VERSION.V1) }`;

    return this.http.post(url, { phone: phone, phoneCode: phoneCode, return_url: _url })
        .pipe(
            tap((response: any) => {
              this.updateUserState(response);
              this.analyticsService.track(AUTH_EVENTS.USER_LOGIN, AUTH_EVENTS.USER_LOGIN);
            }),
        );
  }*/
}
