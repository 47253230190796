import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@shared/api/settings.service';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { BaseObject } from '@shared/base/base-object';
import { OrderState } from '@shared/states/order.state';
import { UserState } from '@shared/states/user.state';
import { DIALOG_DATA, DialogData } from '@ui/dialog/dialog.types';
import { filter, first, takeUntil } from 'rxjs';
import { ApiService } from '@api/api.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent extends BaseObject {
  public isLoginPage = location.pathname === '/login';

  constructor(
    public orderState: OrderState,
    public userState: UserState,
    public settingsService: SettingsService,
    private router: Router,
    private authService: AuthenticationService,
    private apiService: ApiService,
    @Inject(DIALOG_DATA) public dialogData: DialogData<void, void>,
  ) {
    super();
  }

  public toProfile(): void {
    this.dialogData.close();
    this.router.navigate(['account']);
  }

  public toLogin(): void {
    this.dialogData.close();
    location.replace('login');
  }

  public logout(): void {
    this.authService
      .logout()
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(() => location.replace('login'));
  }

  public resetOrder(): void {
    this.apiService
      .resetOrder()
      .pipe(
        first(),
        filter((result) => !!result),
      )
      .subscribe((result) => location.replace('configurator'));
  }
}
