import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { SettingsService } from '@shared/api/settings.service';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { BaseObject } from '@shared/base/base-object';
import { AppScrollStrategy } from '@shared/config/scroll-strategy';
import { OrderState } from '@shared/states/order.state';
import { UserState } from '@shared/states/user.state';
import { getDialogPosition } from '@ui/dialog/dialog-position';
import { DialogService } from '@ui/dialog/dialog.service';
import { ProfileMenuComponent } from '../dialogs/profile-menu/profile-menu.component';

@Component({
  selector: 'app-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountNavComponent extends BaseObject {
  public get isLoginPage(): boolean {
    return location.pathname === '/login';
  }

  private accountIcon: ElementRef;

  @ViewChild('accountIcon', { read: ElementRef }) public set content(content: ElementRef) {
    if (content) {
      this.accountIcon = content;
    }
  }

  constructor(
    private authService: AuthenticationService,
    public orderState: OrderState,
    public userState: UserState,
    public settingsService: SettingsService,
    private dialogService: DialogService,
  ) {
    super();
  }

  public openProfileMenu() {
    this.dialogService.open<ProfileMenuComponent, void>(ProfileMenuComponent, null, {
      width: '280px',
      height: 'auto',
      position: getDialogPosition(this.accountIcon.nativeElement, 'right'),
      autoFocus: false,
      backdropClass: 'app-dialog-no-backdrop',
      scrollStrategy: new AppScrollStrategy(),
    });
  }
}
