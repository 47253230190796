import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import {
  ApartmentParameters,
  LivingApartment,
  ParamsForGettingApartments,
  ResidentalComplex,
  SaveApartmentParameters,
} from '@shared/types/living.types';
import {
  RepairOption,
  RepairOptionsGroupItem,
} from '@configurator/modules/repair-type-page/repair-type-page.types';
import { UserProfile } from '@shared/authentication/interfaces';
import { Catalogs, OrderContainer, Project, ProjectCalculate } from '@shared/states/order.state';
import { SettingsService } from './settings.service';

export interface ApartmentSettingsResponse {
  apart_param: { [key: string]: string | number };
  calculate: ProjectCalculate;
}

export interface RepairOptionsResponse {
  id: number;
  options: RepairOption[];
  side_info: {
    calculate: ProjectCalculate;
  };
}

export interface RepairOptionsGroupResponse {
  options: RepairOptionsGroupItem[];
  calculate: ProjectCalculate;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly settingsService: SettingsService,
  ) {}

  public getComplexesList(): Observable<ResidentalComplex[]> {
    const params = {
      cityIds: '47,78',
      realityType: 'RESALE',
    };

    return this.http
      .get<{ data: ResidentalComplex[] }>(`${this.settingsService.livingApi}partner-api/complex`, {
        params,
        headers: new HttpHeaders({ origin: 'https://ugol.me/' }),
      })
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getApartment(id: string): Observable<LivingApartment> {
    const body = {
      cityIds: [47, 78],
      id: id,
    };
    return this.http
      .post<{ data: LivingApartment }>(
        `${this.settingsService.livingApi}apartment/extended-card`,
        body,
        {
          headers: new HttpHeaders({ origin: 'https://ugol.me/' }),
        },
      )
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getApartmentsList(
    id: string,
    payload: ParamsForGettingApartments,
  ): Observable<LivingApartment[]> {
    if (!payload.roomsCntForFilter) {
      return of(null);
    }

    const params: Record<string, string | string[] | number | number[]> = {};

    params['roomsCntForFilter'] = payload.roomsCntForFilter;

    if (payload.areaTotalFrom) {
      params['areaTotalFrom'] = payload.areaTotalFrom;
    }

    if (payload.areaTotalTo) {
      params['areaTotalTo'] = payload.areaTotalTo;
    }

    if (payload.planningIds) {
      params['planningIds'] = payload.planningIds;
    }

    return this.http
      .post<{ data: LivingApartment[] }>(
        `${this.settingsService.livingApi}partner-api/complex/${id}/apartment`,
        payload,
      )
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getListApartmentLayout(id: number) {
    return this.http
      .post<{ data: [] }>(`${this.settingsService.livingApi}partner-api/complex/${id}/planning`, {
        observe: 'response',
      })
      .pipe(
        catchError((err) => of(null)),
        map((result) => result?.data),
      );
  }

  public getOrder(onlyProjects = false): Observable<OrderContainer> {
    return this.http
      .get<OrderContainer>(`${this.settingsService.v2}client/configurator/get-order`, {
        params: { onlyProjects },
      })
      .pipe(catchError((err) => of(null)));
  }

  public getAnonymousOrder(saveOrder: boolean): Observable<unknown> {
    return this.http
      .get<unknown>(
        `${this.settingsService.v2}client/configurator/get-anonymous-order?${saveOrder}`,
      )
      .pipe(catchError((err) => of(null)));
  }
  public getApartmentParameters(params: HttpParams): Observable<ApartmentParameters> {
    return this.http
      .get<ApartmentParameters>(`${this.settingsService.v2}client/configurator/get-front-params`, {
        params,
      })
      .pipe(catchError((err) => of(null)));
  }

  public sendApartmentSettings(params: SaveApartmentParameters): Observable<ProjectCalculate> {
    return this.http
      .post<ProjectCalculate>(`${this.settingsService.v2}client/configurator/save-params`, params)
      .pipe(catchError((err) => of(null)));
  }

  // public getLeftPanelData(): Observable<LeftPanelData> {
  //   return this.http
  //     .get<LeftPanelData>(`${this.settingsService.v2}client/configurator/get-side`)
  //     .pipe(catchError((err) => of(null)));
  // }

  public getCatalogList(): Observable<Catalogs> {
    return this.http
      .get<Catalogs>(`${this.settingsService.v2}client/configurator/get-catalog`)
      .pipe(catchError((err) => of(null)));
  }

  public updateRepairType(level: number): Observable<OrderContainer> {
    return this.http
      .post<OrderContainer>(`${this.settingsService.v2}client/configurator/save-repair-type`, {
        repairType: level,
      })
      .pipe(catchError((err) => of(null)));
  }

  public setActiveProject(projectId: number): Observable<Project> {
    return this.http
      .post<Project>(`${this.settingsService.v2}client/configurator/set-active-project`, {
        projectId: projectId,
      })
      .pipe(catchError((err) => of(null)));
  }

  public createProject(project: {
    name: string;
    repairTypeId: number;
    templateId: number;
  }): Observable<Project> {
    return this.http
      .post<Project>(`${this.settingsService.v2}client/configurator/create-self-project`, project)
      .pipe(catchError((err) => of(null)));
  }

  public cloneProject(projectId: number): Observable<Project> {
    return this.http
      .post<Project>(`${this.settingsService.v2}client/configurator/clone-project`, {
        projectId: projectId,
      })
      .pipe(catchError((err) => of(null)));
  }

  public deleteProject(projectId: number): Observable<any> {
    return this.http
      .delete(`${this.settingsService.v2}client/configurator/delete-project`, {
        params: {
          projectId,
        },
      })
      .pipe(catchError((err) => of(null)));
  }

  public updateBrigadeLevel(level: number): Observable<{ calculate: ProjectCalculate }> {
    return this.http
      .post<{ calculate: ProjectCalculate }>(
        `${this.settingsService.v2}client/configurator/save-brigade-level`,
        {
          brigadeLevel: level,
        },
      )
      .pipe(catchError((err) => of(null)));
  }

  public updateMaterialLevel(level: number): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.settingsService.v2}client/configurator/save-material-level`, {
        materialLevel: level,
      })
      .pipe(catchError((err) => of(false)));
  }

  public getRepairOptions(): Observable<RepairOptionsGroupItem[]> {
    return this.http
      .get<RepairOptionsGroupItem[]>(`${this.settingsService.v2}client/configurator/get-options`)
      .pipe(catchError((err) => of(null)));
  }

  public updateRepairTypeOptions(options: unknown): Observable<Project> {
    return this.http
      .post<Project>(`${this.settingsService.v2}client/configurator/save-options`, options)
      .pipe(catchError((err) => of(null)));
  }

  public resetParameters(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.settingsService.v2}client/configurator/reset-params`)
      .pipe(catchError((err) => of(false)));
  }

  public resetOptions(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.settingsService.v2}client/configurator/reset-options`)
      .pipe(catchError((err) => of(false)));
  }

  public resetOrder(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.settingsService.v2}client/configurator/clear-order`)
      .pipe(catchError((err) => of(false)));
  }

  public getProfile(): Observable<UserProfile> {
    return this.http
      .get<UserProfile>(`${this.settingsService.v2}client/configurator/get-profile`)
      .pipe(catchError((err) => of(null)));
  }

  public updateProfile(formData: FormData): Observable<UserProfile> {
    return this.http
      .post<UserProfile>(`${this.settingsService.v2}client/configurator/update-profile`, formData)
      .pipe(catchError((err) => of(null)));
  }

  public deleteAvatar(): Observable<UserProfile> {
    return this.http.get<UserProfile>(
      `${this.settingsService.v2}client/configurator/update-profile-delete-avatar`,
    );
  }
}
